.spinner {
    width: 100%;
    height: 100%;
}

.spinner > div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}
